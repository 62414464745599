export default function service() {
	return {
		mobileShowMore: false,
		desktopShowMore: false,
		init() {
			addEventListener("resize", () => {
				if (window.innerWidth <= 640 && this.desktopShowMore) {
					this.desktopShowMore = false;
				}
			});
		},
		toggle() {
			if (window.innerWidth > 640) {
				this.desktopShowMore = !this.desktopShowMore;
			} else {
				this.mobileShowMore = !this.mobileShowMore;
			}
		},
	};
}
