export default function header() {
	return {
		menuOpen: false,
		init() {
			this.checkViewportSize();
			addEventListener("resize", () => {
				this.checkViewportSize();
			});
		},
		checkViewportSize() {
			if (window.innerWidth > 640 && this.menuOpen) {
				this.menuOpen = false;
			}
		},
		toggleMenu() {
			this.menuOpen = !this.menuOpen;
		},
	};
}
