export default function form() {
	return {
		success: false,
		submitText: "Submit",
		submitForm() {
			const form = this.$root.querySelector("form");
			if (this.$validate.isComplete(form)) {
				const formData = new FormData(form);
				formData.append("action", "contact-form/send");
				this.submitText = "Submitting";
				fetch("/", {
					method: "POST",
					body: formData,
					headers: {
						Accept: "application/json",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						this.success = true;
					});
			}
		},
	};
}
